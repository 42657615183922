@tailwind base;
@tailwind components;
@tailwind utilities;

.min-h-chart {
  min-height: 500px;
}

.datamecanic-datepicker--container .react-datepicker {
  border: none;
}

.datamecanic-datepicker--container .react-datepicker__header {
  background: #fff;
  border: none;
}
.datamecanic-datepicker--container .react-datepicker__month {
  margin: 0;
}

/*.react-datepicker__day--keyboard-selected,*/
/*.react-datepicker__month-text--keyboard-selected,*/
/*.react-datepicker__quarter-text--keyboard-selected,*/
/*.react-datepicker__year-text--keyboard-selected {*/
/*  background-color: #2563ebff;*/
/*}*/

/*.react-datepicker__day--selected,*/
/*.react-datepicker__day--in-selecting-range,*/
/*.react-datepicker__day--in-range,*/
/*.react-datepicker__month-text--selected,*/
/*.react-datepicker__month-text--in-selecting-range,*/
/*.react-datepicker__month-text--in-range,*/
/*.react-datepicker__quarter-text--selected,*/
/*.react-datepicker__quarter-text--in-selecting-range,*/
/*.react-datepicker__quarter-text--in-range,*/
/*.react-datepicker__year-text--selected,*/
/*.react-datepicker__year-text--in-selecting-range,*/
/*.react-datepicker__year-text--in-range {*/
/*  background-color: #88adff;*/
/*}*/
